<script>
//import Multiselect from "vue-multiselect";
import required from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";




export default {
  props: { 
      oldTabelas: { type: Object },
      listGrupos2: { type: Array },
     /// parentSelected: { type: Object, required: true }
       ola: {type: Object}
    },
  components: { 
    Multiselect 
    },
  data() {
    return {
      loader: {get: false},
      titleBody: 'Alterar Grupo',
      tabelas:{
        ttp_nome: null,
        
      },
      motorista: [
        {
          id: 1,
          nome: "motorista1",
        },
      ],
      veiculos: [
        {
          id: 1,
          nome: "veiculos1",
        },
      ],
      rotas: [
        {
          id: 1,
          nome: "rota1",
        },
      ],
      insert_embarque: {
        motorista_id: null,
        veiculo_id: null,
        rota_id: null,
        identificacao: null,
        notas: null,
        data_carregamento: null,
        data_previsao: null,
        obs: null,
      },
      select_motorista: null,
      select_rota: null,
      select_veiculos: null,
      
    }
    
    
  },
  validations: {
    tabelas:{
        ttp_nome: {required}, 
      }
  },
  created(){

  },
  mounted() {
    this.setNewTitle();
    console.log(this.ola)
    this.insert_embarque = this.ola
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      await this.$v.$touch();

      this.validatesFields()
        .then(() => {
          this.setPut();
        });
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    setPut() {
      console.log('Gravou');
      console.log(this.insert_embarque)
      this.$emit('doPut', this.insert_embarque);
    },
    seleciona_moto(event) {
      //console.log(event)
      this.insert_embarque.motorista_id = event.id;
    },
    seleciona_rota(event) {
      this.insert_embarque.rota_id = event.id;
      //console.log(event)
    },
    seleciona_veiculo(event) {
      this.insert_embarque.veiculo_id = event.id;
      ///console.log(event)
    },
   
  },
    
}
</script>

<template>

<div class="card">
    <div class="card-body">
      <div class="row mb-4">
        <div class="col-md-4">
          <!-- insert_embarque: {
        motorista: null,
        veiculo: null,
        rota: null,
        identificacao: null,
        notas: null,
        data_carregamento: null,
        data_previsao: null,
        obs: null,
      }, -->
          <Label>{{ "*Motorista" }}</Label>
          <Multiselect
            @select="seleciona_moto($event)"
            v-model="select_motorista"
            :options="motorista"
            label="nome"
          >
          </Multiselect>
        </div>
        <div class="col-md-4">
          <Label>{{ "*Veiculos" }}</Label>
          <Multiselect
            @select="seleciona_veiculo($event)"
            v-model="select_veiculos"
            :options="veiculos"
            label="nome"
          >
          </Multiselect>
        </div>
        <div class="col-md-4">
          <Label>{{ "*Rota/Destino" }}</Label>
          <Multiselect
            @select="seleciona_rota($event)"
            v-model="select_rota"
            :options="rotas"
            label="nome"
          >
          </Multiselect>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-3">
          <Label>{{ "*Identificação" }}</Label>
          <input
            v-model="insert_embarque.identificacao"
            type="text"
            class="form-control text-left"
          />
        </div>
        <div class="col-md-3">
          <Label>{{ "Notas" }}</Label>
          <input
            v-model="insert_embarque.notas"
            type="text"
            class="form-control text-left"
          />
        </div>
        <div class="col-md-3">
          <Label>{{ "previsão de Carregamento" }}</Label>
          <input
            v-model="insert_embarque.data_carregamento"
            type="date"
            class="form-control text-left"
          />
        </div>
        <div class="col-md-3">
          <Label>{{ "previsão de Entrega" }}</Label>
          <input
            v-model="insert_embarque.data_previsao"
            type="date"
            class="form-control text-left"
          />
        </div>
      </div>

      <div class="row mt-4 d-flex justify-content-center align-items-center">
        <div class="col-md-12 text-center">
          <!-- <b-form-checkbox> Pedidos </b-form-checkbox> -->
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pedido variant="light">pedido</b-button>
          </b-card-header>
          <b-collapse id="pedido" accordion="pedido-accordion" role="tabpanel">
            {{ "INSERIR UM PEDIO PARA ESTE MOTORISTA" }}
          </b-collapse>
        </div>
      </div>
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.iten variant="light">Observação</b-button>
      </b-card-header>
      <b-collapse id="iten" accordion="pedido-accordion" role="tabpanel">
        <div class="row mt-4 d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center">
            <textarea
              v-model="insert_embarque.obs"
              name=""
              id=""
              cols="30"
              rows="10"
              class="form-control text-left"
            ></textarea>
          </div>
        </div>
      </b-collapse>
      <div class="row mt-4 d-flex justify-content-center align-items-center">
        <div class="col-md-12 text-center">
          <button class="btn btn-secondary" @click="setPut()">Salvar</button>
        </div>
      </div>
    </div>
  </div>
</template>