<template>
  <b-modal
    v-model="show_modal"
    size="xl"
    title="Registara Abastecimento"
    hide-footer
    @hidden="back()"
  >
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-md-4">
            <Label>*Motorista:</Label>
            <multiselect
              disabled
              v-model="select_moto"
              :options="motoristas"
              label="pes_apelido"
            ></multiselect>
          </div>
          <div class="col-md-4">
            <Label>*Veiculo:</Label>
            <!-- @select="seleciona_veiculo($event)" -->
            <multiselect
              disabled
              v-model="select_veiculo"
              :options="veiculos"
              label="nomeRazao"
            ></multiselect>
          </div>
          <div class="col-md-4">
            <Label>*Data:</Label>
            <input
              v-model="item_abastece.data"
              type="date"
              class="form-control text-left"
            />
          </div>
        </div>
        <div class="row mb-4 mt-4">
          <div class="col-md-4">
            <Label>*Aviso de Embarque:</Label>
            <input
              disabled
              v-model="item_abastece.aviso_embarque_id"
              type="text"
              class="form-control text-left"
            />
          </div>
          <div class="col-md-4">
            <Label>Abestecimento em posto proprio:</Label>
            <multiselect
              v-model="select_proprio"
              :options="postos_proprios"
              label="nome"
              @select="selecionar_posto($event)"
            ></multiselect>
          </div>
          <div class="col-md-4">
            <Label>*Tipo de Combustivel:</Label>
            <multiselect
              @select="seleciona_combustivel($event)"
              v-model="select_tp_combustivel"
              :options="combustiveis"
              label="nome"
            ></multiselect>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <Label>Quantidade de Listros:</Label>
            <input
              v-model="item_abastece.qtd_litros"
              type="text"
              class="form-control text-left"
            />
          </div>
          <div class="col-md-3">
            <Label>Km inicial do veiculo:</Label>
            <input
              @input="formatInput_valor_km_inicial()"
              v-model="item_abastece.km_inicial"
              type="text"
              class="form-control text-left"
            />
          </div>
          <div class="col-md-3">
            <Label>Km Final do veiculo:</Label>
            <input
              @input="formatInput_valor_km_final()"
              v-model="item_abastece.km_final"
              type="text"
              class="form-control text-left"
            />
          </div>
          <div class="col-md-3">
            <Label>Preço do Litro:</Label>
            <input
              @input="formatInput_valor_p_litro()"
              v-model="item_abastece.p_litro"
              type="text"
              class="form-control text-left"
            />
          </div>
        </div>
        <div class="row mt-4 d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center">
            <Label class="mr-2"
              >Total em R$ :
              <p class="font">
                <b-badge
                  data-name="Total"
                  class="field-status"
                  variant="success"
                  >{{ select_total }}</b-badge
                >
              </p></Label
            >
          </div>
          <div class="col-md-12 mt-4 text-center">
            <b-form-checkbox> Gerar Dispesas financeira </b-form-checkbox>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-12">
            <Label>*Descrição</Label>
            <input
              v-model="item_abastece.desc"
              type="text"
              class="form-control text-left"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <Label>Observações</Label>
            <input
              v-model="item_abastece.obs"
              type="text"
              class="form-control text-left"
            />
          </div>
        </div>
        <div class="row mt-4 d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center">
            <button class="btn btn-secondary" @click="salva_item()">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Multiselect from "vue-multiselect";
import { http } from "../../../../helpers/easyindustriaapi/config";

export default {
  props: {
    modal_abr: { type: Boolean },
    prestacao_objeto: { type: Object },
    veiculos_array: { type: Array },
    motoristas_array: { type: Array },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      show_modal: false,
      item_abastece: {
        motorista_id: null,
        veiculo_id: null,
        data: null,
        aviso_embarque_id: null,
        sit_abst_proprio: null,
        tp_combustivel: null,
        qtd_litros: null,
        km_inicial: null,
        p_litro: null,
        total: null,
        gera_disp_fin: null,
        desc: null,
        obs: null,
        posto_prop: null,
      },
      postos_proprios: [
        {
          id: 1,
          nome: "sim",
        },
        {
          id: 2,
          nome: "não",
        },
      ],
      motoristas: [],
      veiculos: [
        // {
        //   id: 1,
        //   nome: "veiculo1",
        // },
        // {
        //   id: 2,
        //   nome: "veiculo12",
        // },
      ],
      select_veiculo: null,
      combustiveis: [
        {
          id: 1,
          nome: "disel",
        },
        {
          id: 2,
          nome: "gasolina",
        },
        {
          id: 3,
          nome: "alcool",
        },
      ],
      select_proprio: null,
      select_tp_combustivel: null,
      select_moto: null,
      select_total: null,
    };
  },
  created() {
    this.show_modal = this.modal_abr;
    this.item_abastece.motorista_id = this.prestacao_objeto.motorista_id;
    //this.select_moto = this.prestacao_objeto
    this.item_abastece.veiculo_id = this.prestacao_objeto.veiculo_id;
    this.item_abastece.empresa_id = this.prestacao_objeto.empresa_id;
    this.veiculos = this.veiculos_array;
    this.motoristas = this.motoristas_array;
    this.item_abastece.aviso_embarque_id =
      this.prestacao_objeto.aviso_embarque_id;
  },
  mounted() {
    this.inicializar_multiselcts();
    // console.log("veiculos Array");
    // console.log(this.veiculos);
  },
  methods: {
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    verificar_valores_obrigatorios() {},
    selecionar_posto(event) {
      this.item_abastece.posto_prop = event.id;
    },
    post_abastecimento() {},
    coverter_float() {
      //typeof(this.pre_ped.valor)
      if (typeof this.item_abastece.km_inicial == "string") {
        this.item_abastece2 = this.item_abastece;
        let stringValor = this.item_abastece2.km_inicial;
        let valorFlutuante = parseFloat(
          stringValor.replace("R$", " ").replace(/\./g, "").replace(",", ".")
        );
        this.item_abastece.km_inicial = valorFlutuante;
      }
      if (typeof this.item_abastece.p_litro == "string") {
        this.item_abastece2 = this.item_abastece;
        let stringValor = this.item_abastece2.p_litro;
        let valorFlutuante = parseFloat(
          stringValor.replace("R$", " ").replace(/\./g, "").replace(",", ".")
        );
        this.item_abastece.p_litro = valorFlutuante;
      }
      if (typeof this.item_abastece.total == "string") {
        this.item_abastece3 = this.item_abastece;
        let stringValor = this.item_abastece3.total;
        let valorFlutuante = parseFloat(
          stringValor.replace("R$", " ").replace(/\./g, "").replace(",", ".")
        );
        this.item_abastece.total = valorFlutuante;
      }
    },
    soma_total() {
      console.log(this.item_abastece.p_litro);
      if (
        typeof this.item_abastece.qtd_litros == "string" &&
        typeof this.item_abastece.p_litro == "string"
      ) {
        let qtd_litro = parseInt(this.item_abastece.qtd_litros);
        let abastecimento2 = this.item_abastece;
        let valorSrting = abastecimento2.p_litro;
        let valorFlutuante = parseFloat(
          valorSrting.replace(/\./g, "").replace(",", ".")
        );
        let p_litro = valorFlutuante;
        let total = p_litro * qtd_litro;
        this.select_total = this.formatterCurrBR(total);
        this.item_abastece.total = total;
      }
    },
    formatInput_valor_km_inicial() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.item_abastece.km_inicial.replace(/\D/g, "");
      // Formate o valor_pag numérico para a moeda desejada (por exemplo, BRL)
      this.item_abastece.km_inicial = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    formatInput_valor_km_final() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.item_abastece.km_final.replace(/\D/g, "");
      // Formate o valor_pag numérico para a moeda desejada (por exemplo, BRL)
      this.item_abastece.km_final = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    formatInput_valor_p_litro() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.item_abastece.p_litro.replace(/\D/g, "");
      // Formate o valor_pag numérico para a moeda desejada (por exemplo, BRL)
      this.item_abastece.p_litro = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", "");
      this.soma_total();
    },
    // selecionar_posto(event){
    //   this.item_abastece.posto_prop = event.id
    // },
    seleciona_motorista(event) {
      this.item_abastece.motorista_id = event.id;
    },
    seleciona_combustivel(event) {
      this.item_abastece.tp_combustivel = event.id;
    },
    seleciona_veiculo(event) {
      this.item_abastece.veiculo_id = event.id;
    },
    inicializar_multiselcts() {
      let veiculo_selecionado = this.veiculos.find(
        (v) => v.id == this.prestacao_objeto.veiculo_id
      );
      this.select_veiculo = veiculo_selecionado;
      // console.log(this.select_veiculo);

      console.log(this.motoristas);
      let motorista_selecionado = this.motoristas.find(
        (m) => m.id == this.prestacao_objeto.motorista_id
      );
      this.select_moto = motorista_selecionado;
    },
    back() {
      this.$emit("back");
    },
    salva_item() {
      this.coverter_float();
      // console.log(this.item_abastece)
      this.postAbastecimento();
    },
    async postAbastecimento() {
      // embarque.empresa_id = this.currentEmpresa.id;
      // embarque.user_id = this.currentUser.id;
      //this.onLoader();
      try {
        let response = await http.post(
          "/abastecimento?empresa_id=" + this.prestacao_objeto.empresa_id,
          this.item_abastece
        );
        if (response) {
          //this.offLoader();
          if (response.status === 200) {
            //this.getData();
            this.inserindo = false;
            this.makeToast("success", "Registro incluído");
            this.$emit("back");
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.font {
  font-size: 30px;
}
.estilo_input {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>