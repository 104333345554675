<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <Label>Aviso de Embarque:</Label>
            <input type="text" class="form-control text-left" />
          </div>
          <div class="col-md-4">
            <Label>Motorista:</Label>
            <input type="text" class="form-control text-left" />
          </div>
          <div class="col-md-4">
            <Label>Data Pagamento:</Label>
            <input type="date" class="form-control text-left" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-table-simple>
              <b-thead id="b-head">
                <b-tr>
                  <b-th class="b-th">Código</b-th>
                  <b-th class="b-th">Código C custo</b-th>
                  <b-th class="b-th">Débito</b-th>
                  <b-th class="b-th">Código Centro Resultado</b-th>
                  <b-th class="b-th">Crédito</b-th>
                  <b-th class="b-th">Valor</b-th>
                  <b-th class="b-th">Ativo</b-th>
                  <!-- <b-th class="b-th">Ações</b-th> -->
                  <b-th>&nbsp;</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td></b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <button class="btn btn-secondary" @click="setPost()">
              Confirmar Pagamento
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    objeto: { type: Boolean },
    //abr_modal: { type: Bollean}
  },
  data() {
    return {
      show_modal: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    back() {
      this.$emit("back");
    },
    setPost() {
      console.log("fazzer o post aqui ");
    },
  },
};
</script>

<style>
</style>