<template>
  <b-modal
    v-model="modal_rota"
    size="xl"
    title="Cadastro de Rota"
    hide-footer
    @hidden="back()"
  >
    <div class="card">
      <div class="card-body">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center">
            <Label>Rota:</Label>
            <input
              v-model="rota.nome"
              type="text"
              class="form-control text-left"
              placeholder="digite a rota"
            />
          </div>
        </div>
        <div class="row d-flex justify-content-center align-items-center mt-4">
          <div class="col-md-12 text-center">
            <button class="bnt btn-secondary" @click="doPost()">salvar</button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { http } from "../../../../helpers/easyindustriaapi/config";
export default {
  props: {
    open_modal_rota: { type: Boolean },
  },
  data() {
    return {
      modal_rota: false,
      rota: {
        nome: null,
      },
    };
  },
  created() {
    this.modal_rota = this.open_modal_rota;
    this.currentUser = JSON.parse(localStorage.getItem("user")); // para saber qual o usuario atual;
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
  },
  mounted() {},
  methods: {
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    back() {
      this.$emit("back");
    },
    async doPost(rota) {
      rota = this.rota;
      rota.empresa_id = this.currentEmpresa.id;
      // embarque.user_id = this.currentUser.id;
      //this.onLoader();
      try {
        let response = await http.post(
          "/rota?empresa_id=" + this.currentEmpresa.id,
          rota
        );
        if (response) {
          //this.offLoader();
          if (response.status === 200) {
            //this.getData();
            //this.inserindo = false;
            this.makeToast("success", "Registro incluído");
          }
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
  },
};
</script>

<style scoped>
</style>