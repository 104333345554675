<template>
  <div class="card">
    <div class="card-body">
      <div class="row mt-4 mb-4">
        <div class="col-md-4">
          <Label>Aviso:</Label>
          <input
            v-model="prestacao.id"
            disabled
            type="text"
            class="form-control text-left"
          />
        </div>
        <div class="col-md-4">
          <Label>Veiculo:</Label>
          <multiselect
            disabled
            v-model="select_veiculo"
            label="nomeRazao"
            :options="veiculos"
          ></multiselect>
        </div>
        <div class="col-md-4">
          <Label>Motorista:</Label>
          <multiselect
            disabled
            v-model="select_motorista"
            label="pes_apelido"
            :options="motoristas"
          ></multiselect>
        </div>
      </div>
      <div class="row mt-4 mb-4">
        <div class="col-md-3">
          <Label>Km inicial do veiculo:</Label>
          <input
            disabled
            v-model="prestacao.km_inicial"
            type="text"
            class="form-control text-left"
          />
        </div>
        <div class="col-md-3">
          <Label>*Km final do veiculo:</Label>
          <input
            v-model="prestacao.km_final"
            @input="formatInput_valor_km_final()"
            type="text"
            class="form-control text-left"
          />
        </div>
        <div class="col-md-3">
          <Label>*Situação:</Label>
          <multiselect
            v-model="select_situacao"
            :options="situacoes"
            label="nome"
            @select="seleciona_situacao($event)"
          ></multiselect>
        </div>
        <div class="col-md-3">
          <Label>*Data de Entrega(Final):</Label>
          <input
            v-model="prestacao.data_final"
            type="date"
            class="form-control text-left"
          />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-12">
          <!-- b-colpase -->
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.abastecimento variant="success"
              >Abastecimentos
            </b-button>
          </b-card-header>
          <!-- visible - para deixar aberto é so colocar esse comando no colapse -->
          <b-collapse
            id="abastecimento"
            accordion="pedido-accordion"
            role="tabpanel"
          >
            <Label>Abastecimentos</Label>
            <b-table
              striped
              hover
              :items="abastecimentos"
              responsive
              id="doc-itens"
              :fields="fields"
            >
              <template #cell(excluir)="row">
                <button
                  @click="dlt_abastecimento(row.item)"
                  class="btn btn-danger"
                >
                  Excluir
                </button>
              </template>
              <template #cell(total)="row">
                {{ formatterCurrBR(row.item.total) }}
              </template>
            </b-table>
            <div class="row d-flex justify-content-center align-items-center">
              <div class="col-md-12 text-right">
                <button class="btn btn-secondary" @click="modal_reg()">
                  + Registrar
                </button>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
      <!-- fim da linha  -->
      <div class="row">
        <div class="col-md-12">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.registroFinc variant="warning"
              >Registro Financeiros
            </b-button>
          </b-card-header>
          <!-- visible - para deixar aberto é so colocar esse comando no colapse -->
          <b-collapse
            id="registroFinc"
            accordion="pedido-accordion"
            role="tabpanel"
          >
            <Label>Registro Financeiros: </Label>
            <b-table
              striped
              hover
              :items="recebimentos"
              responsive
              id="doc-itens"
              :fields="fields_recebimento"
            >
              <template #cell(excluir)="row">
                <button
                  @click="dlt_recebimento()(row.item)"
                  class="btn btn-danger"
                >
                  Excluir
                </button>
              </template>
            </b-table>
            <div
              class="row mb-4 mt-4 d-flex justify-content-center align-items-center"
            >
              <div class="col-md-12 text-right">
                <button
                  class="btn btn-secondary mr-2"
                  @click="modal_despesas()"
                >
                  Registrar Despesa
                </button>
                <button
                  class="btn btn-secondary mr-2"
                  @click="modal_recebimento()"
                >
                  Registrar Recebimento
                </button>
                <button
                  class="btn btn-secondary"
                  @click="modal_transferencia()"
                >
                  Registrar Transferencia
                </button>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
      <div
        v-if="abesteciment_modal"
        class="row d-flex justify-content-center align-items-center"
      >
        <div class="col-md-12 text-right">
          <Registrar
            @salva_item="salva_item"
            @back="back"
            :prestacao_objeto="conta_atual"
            :modal_abr="abesteciment_modal"
            :veiculos_array="veiculos"
            :motoristas_array="motoristas"
          />
        </div>
      </div>

      <div v-if="veri_transferencia" class="row">
        <div class="col-md-12">
          <transferencia
            :veri_transferencia="veri_transferencia"
            @back="back"
          ></transferencia>
        </div>
      </div>
      <div v-if="veri_despe" class="row">
        <div class="col-md-12">
          <despesa :veri_desp="veri_despe" @back="back"></despesa>
        </div>
      </div>
      <div v-if="veri_reg_receb" class="row">
        <div class="col-md-12">
          <regRec
            @item_recebimento="item_recebimento"
            :prestacao_objeto="conta_atual"
            :veri_reg_receb="veri_reg_receb"
            @back="back"
          />
        </div>
      </div>
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.outros variant="info"
          >Outras Informações
        </b-button>
      </b-card-header>
      <!-- visible - para deixar aberto é so colocar esse comando no colapse -->
      <b-collapse id="outros" accordion="pedido-accordion" role="tabpanel">
        <div class="row mt-4">
          <div class="col-md-4">
            <Label>Observações:</Label>
            <textarea
              v-model="prestacao.obs"
              cols="30"
              rows="1"
              class="form-control text-left"
            ></textarea>
          </div>
          <div class="col-md-4">
            <Label>Esta prestação esta Finalizada ?:</Label>
            <!-- prestacao.finalizada -->
            <multiselect
              @select="seleciona_finalizada($event)"
              label="nome"
              v-model="select_finalizada"
              :options="prestacoes"
            ></multiselect>
          </div>
          <div class="col-md-4">
            <Label>Previsão para Prestar Conta:</Label>
            <input
              v-model="prestacao.previsao"
              type="date"
              class="form-control text-left"
            />
          </div>
        </div>
      </b-collapse>
      <div class="row mt-4 d-flex justify-content-center align-items-center">
        <div class="col-md-12 text-center">
          <button class="btn btn-secondary" @click="escolher_request()">
            Salvar
          </button>
          <!-- <button class="btn btn-secondary" @click.prevent="desvicular_prestacao()" >Desvicular Prestação</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Registrar from "./registar_abastecimento.vue";
import regRec from "./registrar_recebimento.vue";
import despesa from "./regdespesa.vue";
import transferencia from "./regTranferencia.vue";
import { http } from "../../../../helpers/easyindustriaapi/config";
export default {
  props: {
    conta_atual: { type: Object },
  },
  components: {
    Multiselect,
    Registrar,
    regRec,
    despesa,
    transferencia,
  },
  data() {
    return {
      select_situacao: null,
      situacoes: [
        {
          id: 1,
          nome: "embarcado",
        },
        {
          id: 2,
          nome: "entregue",
        },
      ],
      select_motorista: null,

      fields_recebimento: [
        {
          label: "Data Recebimento",
          key: "data_recebimento",
          tdClass: "text-center",
          thClass: "text-center",
          variant: "info",
        },

        {
          label: "N° doc",
          key: "n_doc",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Valor",
          key: "vlr_recebido",
          tdClass: "text-center",
          thClass: "text-center",
          variant: "info",
        },
        {
          label: "Descrição",
          key: "desc",
          tdClass: "text-center",
          thClass: "text-center",
        },
        // { label: "Número", key: "numero", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Série", key: "serie", tdClass: 'text-center', thClass: 'text-center', formatter: "formatterCurrBR" },
        // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
        {
          label: "Ações",
          key: "excluir",
          tdClass: "text-center",
          thClass: "text-center",
          variant: "info",
        },
      ],
      fields: [
        {
          label: "Data",
          key: "data",
          tdClass: "text-center",
          thClass: "text-center",
          variant: "info",
        },
        {
          label: "Km do veiculo",
          key: "km_inicial",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Qtd Litros",
          key: "qtd_litros",
          tdClass: "text-center",
          thClass: "text-center",
          variant: "info",
        },
        {
          label: "Preço Litro",
          key: "p_litro",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Valor",
          key: "total",
          tdClass: "text-center",
          thClass: "text-center",
          variant: "info",
        },
        {
          label: "Ativo",
          key: "ativo",
          tdClass: "text-center",
          thClass: "text-center",
        },
        // { label: "Número", key: "numero", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Série", key: "serie", tdClass: 'text-center', thClass: 'text-center', formatter: "formatterCurrBR" },
        // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
        {
          label: "Ações",
          key: "excluir",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      abastecimentos: [],
      motoristas: [
        // {
        //   id: 1,
        //   nome: "moto1",
        // },
      ],
      veiculos: [
        {
          id: 1,
          nome: "moto1",
        },
      ],
      prestacoes: [
        {
          id: 1,
          nome: "sim",
        },
        {
          id: 2,
          nome: "não",
        },
      ],
      abesteciment_modal: false,
      item_abastece: {
        motorista_id: null,
        veiculo: null,
        data: null,
        aviso_embarque: null,
        sit_abst_proprio: null,
        tp_combustivel: null,
        qtd_litros: null,
        km_inicial: null,
        preco_litro: null,
        total_real: null,
        gera_disp_fin: null,
        desc: null,
        obs: null,
      },
      select_veiculo: null,
      select_obs: null,
      veri_reg_receb: false,
      prestacao: {},
      prestacoes1: [],
      select_finalizada: null,
      // finalizada:null,
      // previsao_conta:null,
      // obs:null,
      // data_final:null,
      // situacao:null,
      // motorista_id:null,
      // veiculo_id:null,
      // aviso_embarque_id:null,
      recebimentos: [],
      veri_despe: false,
      veri_transferencia: false,
    };
  },
  created() {
    this.select_obs = this.conta_atual.obs;
    this.prestacao = this.conta_atual;
    this.prestacao.aviso_embarque_id = this.conta_atual.id;
    this.currentUser = JSON.parse(localStorage.getItem("user")); // para saber qual o usuario atual;
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
  },
  mounted() {
    //console.log(this.conta_atual);
    this.get_pessoa_motorist();
    this.get_veiculo();
    this.get_prestacao();
    this.get_abastecimentos();
  },
  methods: {
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    modal_transferencia() {
      this.veri_transferencia = true;
    },
    modal_despesas() {
      this.veri_despe = true;
    },
    async get_pessoa_motorist() {
      // depois passar o empresa id , pq senão vai trazer de todas as empresas;
      // + "&empresa_id=" + this.currentEmpresa.id
      try {
        let response = await http.get("/motorista/?motorista_id=" + -6);
        if (response.status === 200) {
          this.motoristas = response.data;
          //   console.log("motoristaas")
          //  console.log(this.motoristas)
          setTimeout(() => {
            //this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.motoristas = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          //this.offLoader();
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    seleciona_finalizada(e) {
      this.prestacao.finalizada = e.id;
    },
    coverter_float() {
      //typeof(this.pre_ped.valor)
      if (typeof this.prestacao.km_final == "string") {
        this.prestacao2 = this.prestacao;
        let stringValor = this.prestacao2.km_final;
        let valorFlutuante = parseFloat(
          stringValor.replace("R$", " ").replace(/\./g, "").replace(",", ".")
        );
        this.prestacao.km_final = valorFlutuante;
      }
      if (typeof this.prestacao.km_inicial == "string") {
        this.prestacao2 = this.prestacao;
        let stringValor = this.prestacao2.km_inicial;
        let valorFlutuante = parseFloat(
          stringValor.replace("R$", " ").replace(/\./g, "").replace(",", ".")
        );
        this.prestacao.km_inicial = valorFlutuante;
      }
    },
    seleciona_situacao(e) {
      let seleciona_sit = e.id;
      this.prestacao.situacao = seleciona_sit;
    },
    async get_veiculo() {
      try {
        let response = await http.get(
          "/veiculo?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.veiculos = response.data;
          //console.log(this.veiculos);
          this.inicializar_valores();
          setTimeout(() => {
            ///this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.veiculos = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          //this.offLoader();
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async get_abastecimentos() {
      try {
        let response = await http.get(
          "/abastecimento/busca_aviso?empresa_id=" +
            this.currentEmpresa.id +
            "&aviso_embarque_id=" +
            this.conta_atual.id
        );
        if (response.status === 200) {
          this.abastecimentos = response.data;
          console.log(this.abastecimentos);
        } else {
          this.abastecimentos = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          //this.offLoader();
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    // faz logica para veriicar se ja tem registros
    // na tabelea Prestações de conta pelo o id do aviso de embarque;
    preenche_valores() {
      let tamanho_vetor_prestacoes = this.prestacoes1.length;
      if (tamanho_vetor_prestacoes > 0) {
        let prestacao_selecionado = this.prestacoes1.find(
          (p) => p.aviso_embarque_id == this.prestacao.aviso_embarque_id
        );
        this.prestacao = prestacao_selecionado;
        let situacao_selecionado = this.situacoes.find(
          (s) => s.id == prestacao_selecionado.situacao
        );
        this.select_situacao = situacao_selecionado;
        let finalizada_selecionado = this.prestacoes.find(
          (p) => p.id == prestacao_selecionado.finalizada
        );
        this.select_finalizada = finalizada_selecionado;
        this.prestacao.km_inicial = this.formatterCurrBR(
          this.prestacao.km_inicial
        );
        this.prestacao.km_final = this.formatterCurrBR(this.prestacao.km_final);
      }
    },
    busca() {
      let id_prestacao_selecionado = this.prestacoes1.find(
        (p) => p.aviso_embarque_id == this.prestacao.aviso_embarque_id
      );
      this.prestacao.id = id_prestacao_selecionado.id;
      // console.log(id_prestacao_selecionado.id)
    },
    set_post_abastecimento() {
      // console.log("entrei no post de prestação ")
      //console.log(this.prestacao.vet_abastecimento);
      this.$emit("post_abastecimento", this.prestacao);
      // console.log("entrei nos abastecimentos")
    },
    escolher_request() {
      let tamanho_vetor_prestacoes = this.prestacoes1.length;
      if (tamanho_vetor_prestacoes > 0) {
        // tenho que fazer um busca para pegar o id da tabela prestação de conta
        // para fazer o update para o lugar corretto;
        this.busca();
        this.setPut();
        //  só faço o post quando tiver prestaçoes
        // as prestações for maior que 0
        // pq sendo maior que 0 eu tenho o aviso_embrque_id associado;
        if (this.abastecimentos.length > 0) {
          this.prestacao.vet_abastecimento = this.abastecimentos;
          // this.set_post_abastecimento();
        }
      } else {
        this.setPost();
        if (this.abastecimentos.length > 0) {
          this.prestacao.vet_abastecimento = this.abastecimentos;
          //this.set_post_abastecimento();
        }
      }
    },
    // isso faz a chamada para o list
    // e do list faz a chamada para o index
    setPut() {
      this.coverter_float();
      this.$emit("setPut", this.prestacao);
    },
    setPost() {
      this.coverter_float();
      this.$emit("setPost", this.prestacao);
    },
    async get_prestacao() {
      //this.onLoader();
      try {
        let response = await http.get(
          "/prestacaoConta?aviso_embarque_id=" +
            this.prestacao.aviso_embarque_id +
            "&empresa_id=" +
            this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.prestacoes1 = response.data;
          // console.log(this.prestacoes1)
          this.preenche_valores();
          setTimeout(() => {
            //    this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.prestacoes1 = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          //this.offLoader();
        }
      } catch (error) {
        //this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    modal_recebimento() {
      this.veri_reg_receb = true;
    },

    inicializar_valores() {
      let motorista_selecionado = this.motoristas.find(
        (e) => e.id === this.conta_atual.motorista_id
      );
      let veiculo_selecionado = this.veiculos.find(
        (e) => e.id === this.conta_atual.veiculo_id
      );
      // let situacao_selecionada = this.situacoes.find((e) => e.id === this.conta_atual.situacao)
      this.select_motorista = motorista_selecionado;
      this.select_veiculo = veiculo_selecionado;
      //   this.select_situacao = situacao_selecionada
      this.prestacao.km_inicial = this.formatterCurrBR(
        this.select_veiculo.km_atual
      );
    },
    modal_reg() {
      this.abesteciment_modal = true;
    },
    add_abastecimento(objeto) {
      this.abastecimentos.push(objeto);
    },
    dlt_abastecimento(e) {
      console.log(e);
      this.deletar_abastecimento_BD(e)
    },
    async deletar_abastecimento_BD(objeto) {
      try {
        let response = await http.delete(
          "/abastecimento/" +
            objeto.id +
            "?empresa_id=" +
            this.currentEmpresa.id,
          objeto
        );
        if (response) {
          if (response.status === 200) {
            this.get_abastecimentos();
            this.makeToast("warning", "Registro excluído");
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast(
              "danger",
              "A uma prestação ou Acopahamento de Viagem Associado ao Aviso de Embarque"
            );
          }
        }
      }
    },
    item_recebimento(objeto) {
      this.veri_reg_receb = false;
      console.log(objeto);
      if (objeto != null) {
        this.add_rebebimento(objeto);
      }
    },
    add_rebebimento(objeto) {
      console.log("adicionadno recebimento");
      console.log(objeto);
      this.recebimentos.push(objeto);
    },
    // Mudara para filter para pode apagar de qualquer posição
    dlt_recebimento() {
      this.recebimentos.pop();
    },
    salva_item(objeto) {
      // console.log("entrei no salva item de prestação ")
      // console.log(objeto)
      this.abesteciment_modal = false;
      if (objeto != null) {
        this.add_abastecimento(objeto);
      }
    },

    formatInput_valor_km_final() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.prestacao.km_final.replace(/\D/g, "");
      // Formate o valor_pag numérico para a moeda desejada (por exemplo, BRL)
      this.prestacao.km_final = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    back() {
      this.get_abastecimentos();
      this.abesteciment_modal = false;
      this.veri_reg_receb = false;
      this.veri_despe = false;
      this.veri_transferencia = false;
    },
  },
};
</script>

<style>
</style>