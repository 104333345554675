<script>
export default {
  props: {
    tabelas: {
      type: Object,
    },
    motoristas: {
      type: Array,
    },
  },
  data() {
    return {
      array_motorista: [],
    };
  },
  mounted() {
    this.busca_motorista();
  },
  created() {
    this.array_motorista = this.motoristas;
  },
  methods: {
    busca_motorista() {
      let objeto_motorista_selecionado = this.array_motorista.find(
        (m) => m.motorista_id == this.tabelas.motorista_id
      );
      console.log("aqui o objeto motorista");
      console.log(objeto_motorista_selecionado);
    },
  },

  computed: {
    getClasse() {
      switch (this.produto.produto_m_s) {
        case "M":
          return "Mercadoria";
        case "S":
          return "Serviço";

        default:
          return "Produto";
      }
    },
  },
};
</script>

<template>
  <div id="produto">
    <p>
      Código: <strong>{{ tabelas.id }}</strong>
    </p>
    <p>
      Motorista: <strong>{{ tabelas.motorista_id }}</strong>
    </p>
    <p>
      <strong>Data do Carregamento :</strong>
      <b-badge data-name="data" class="field-status ml-2" variant="success">
        {{ tabelas.data_carregamento }}
      </b-badge>
    </p>
    <p>
      <strong>Data Prevista :</strong>
      <b-badge data-name="data" class="field-status ml-2" variant="success">
        {{ tabelas.data_previsao }}
      </b-badge>
    </p>
    <p>
      <strong>Observações :</strong>
      <b-badge data-name="data" class="field-status ml-2" variant="warning">
        {{ tabelas.obs }}
      </b-badge>
    </p>

    <!-- <p>Referência: <strong>{{ produto.codbarras }}</strong></p>
        <p>Classe: <strong>{{ getClasse }}</strong></p>
        <p>Tipo: <strong>{{ produto.tipoproduto.descricao }}</strong></p>
        <p>Grupo: <strong>{{ (produto.grupo) ? produto.grupo.descricao : '' }}</strong></p>
        <p>N. C. M.: <strong>{{ produto.produto_ncm }}</strong></p>
        <p>C. E. S. T.: <strong>{{ produto.produto_cest }}</strong></p>
        <p>Preço: <strong>{{ formatterCurrBR(produto.preco) }}</strong></p>
        <p>Custo: <strong>{{ formatterCurrBR(produto.preco_custo) }}</strong></p>
        <p>Atacado: <strong>{{ formatterCurrBR(produto.preco_atacado) }}</strong></p>
        <p>Margem lucro: <strong>{{ formatterFloatBR(produto.produto_margemlucro) }}%</strong></p>
        <p>Unidade medida: {{ (produto.unidade) ? produto.unidade.descricao : '' }}</p>
        <p>Classificação SPED: <strong>{{ (produto.classificacao_sped) ? produto.classificacao_sped.descricao : '' }}</strong></p>
        <p>Situação: <span v-bind:class="(parseInt(produto.ativo) === 0) ? 'text-danger' : 'text-success'">{{ (parseInt(produto.ativo) === 0) ? 'Inativo' : 'Ativo' }}</span></p> -->
  </div>
</template>