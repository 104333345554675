<template>
  <b-modal
    v-model="show_modal"
    size="xl"
    title="Registara Transferecencia"
    hide-footer
    @hidden="back()"
    headerBgVariant="light"
  >
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <Label>Definir Padrão:</Label>
            <multiselect :options="padroes"> </multiselect>
          </div>
          <div class="col-md-4">
            <Label>{{ "De:" }}</Label>
            <input type="text" class="form-control text-left" />
          </div>
          <div class="col-md-4">
            <Label>{{ "Para:" }}</Label>
            <input type="text" class="form-control text-left" />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-3">
            <Label>{{ "*Data" }}</Label>
            <input type="text" class="form-control text-left" />
          </div>
          <div class="col-md-3">
            <Label>{{ "*Valor" }}</Label>
            <input type="text" class="form-control text-left" />
          </div>
          <div class="col-md-3">
            <Label>{{ "*Desc da Saída na Conta de Origem" }}</Label>
            <input type="text" class="form-control text-left" />
          </div>
          <div class="col-md-3">
            <Label>{{ "*Desc da Entrada na Conta de Destino" }}</Label>
            <input type="text" class="form-control text-left" />
          </div>
        </div>
        <!-- teste servidor  -->
        <div class="row mt-4">
          <div class="col-md-6">
            <b-form-checkbox class="mb-2">Vincular a um Aviso de embarque</b-form-checkbox>
            <multiselect :options="avisos"></multiselect>
          </div>
          <div class="col-md-6">
            <Label>&nbsp;</Label>
            <!-- <b-form-checkbox>Vincular a um Aviso de embarque</b-form-checkbox> -->
            <multiselect :options="situacoes"> </multiselect>
          </div>
        </div>
        <div class="mt-4 row row d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center">
            <button class="btn btn-secondary">Salvar</button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import Multiselect from "vue-multiselect";
export default {
  components:{
    Multiselect
  },
  props: {
    veri_transferencia: { type: Boolean },
  },
  data() {
    return {
      show_modal: false,
      situacoes: [
        {
          id: 1,
          nome: "vinculada a origem",
        },
        {
          id: 1,
          nome: "vinculada ao destino",
        },
      ],
      avisos: [
        {
          id: 1,
          nome: "aviso de emabrque 1",
        },
      ],
      padroes: [
        {
          id: 1,
          nome: "padrão1",
        },
      ],
    };
  },
  created() {
    this.show_modal = this.veri_transferencia;
  },
  methods: {
    back() {
      this.$emit("back");
    },
  },
};
</script>
<style>
</style>