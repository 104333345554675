<script>
import Detail from "./detail";
import conta from "./prestaConta.vue";
import carregamento from "./carregamento.vue";
import viagem from "./acompanharViagem.vue";
import { http } from "../../../../helpers/easyindustriaapi/config";

export default {
  props: {
    currentEmpresa: { type: Object, required: true },
    listTabelas: { type: Array, required: true },
    hide: { type: Boolean, required: true },
  },
  components: { Detail, conta, carregamento, viagem },
  data() {
    return {
      titleBody: "Listagem de Embarques",
      currentTabelas: undefined,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        {
          label: "ID",
          key: "id",
          sortable: true,
          tdClass: "text-left",
          thClass: "text-left"
        },
        {
          label: "Situação",
          key: "situacao",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Observações",
          key: "obs",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
          variant: "light",
        },
        {
          label: "Notas",
          key: "notas",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        // { label: "Tag", key: "grupo_tag", sortable: true, tdClass: 'text-left', thClass: 'text-center' },
        //{ label: "ID_Empresa", key: "empresa_id", sortable: false, thClass: 'text-center' },
        // { label: 'Preço', key: 'preco', sortable: false, tdClass: 'text-right', thClass: 'text-center',
        //   formatter: "formatCurrency"},
        {
          label: "Ações",
          key: "acoes",
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      motoristas: [],
      veiculos: [],
    };
  },
  computed: {
    /**
     * Total no. of records
     */

    rows() {
      return this.listTabelas.length ? this.listTabelas.length : 0;
    },
    clonefoot() {
      return this.listTabelas.length || this.listTabelas.length > 10
        ? true
        : false;
    },
  },
  created() {
    // this.currentUser = JSON.parse(localStorage.getItem("user")); // para saber qual o usuario atual;
    // this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
    // this.currentUser = JSON.parse(localStorage.getItem("user")); // para saber qual o usuario atual;
    // this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
  },
  mounted() {
    ///console.log(this.listTabelas)
    this.get_pessoa_motorist();
    this.get_veiculo();
    this.$emit("newTitle", this.titleBody);
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    // eslint-disable-next-line no-unused-vars
    formatCurrency(value, key, item) {
      return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
    post_viagem(objeto) {
      this.$emit("post_viagem", objeto);
    },
    async get_veiculo() {
      try {
        let response = await http.get(
          "/veiculo?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.veiculos = response.data;
          //console.log(this.veiculos);
          this.inicializar_valores();
          setTimeout(() => {
            ///this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.veiculos = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          //this.offLoader();
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async get_pessoa_motorist() {
      try {
        let response = await http.get("/motorista/?motorista_id=" + -6);
        if (response.status === 200) {
          this.motoristas = response.data;
          //   console.log("motoristaas")
          // console.log(this.motoristas)
          setTimeout(() => {
            //this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.motoristas = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          //this.offLoader();
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    setTabelas(value) {
      this.currentTabelas = value;
      this.$refs["modal-tabelas"].show();
    },
    setPrestacao(value) {
      this.currentTabelas = value;
    },
    setCarregamento(value) {
      this.currentTabelas = value;
    },
    setViagem(value) {
      this.currentTabelas = value;
    },
    setEdit(value) {
      this.setTabelas(value);
      this.$emit("edit", value);
    },
    setDelete(value) {
      this.$emit("doDelete", value);
    },
    // async doPost_prestacao(prestacao) {
    //   // id
    //   // DescricaoUn
    //   // un_codigo
    //   // empresa_id

    //   prestacao.empresa_id = this.currentEmpresa.id;
    //   // prestacao.user_id = this.currentUser.id;
    //   this.onLoader();
    //   try {
    //     let response = await http.post(
    //       "/prestacaoConta?empresa_id=" + this.currentEmpresa.id,
    //       prestacao
    //     );
    //     if (response) {
    //       this.offLoader();
    //       if (response.status === 200) {
    //         this.getData();
    //         this.inserindo = false;
    //         this.makeToast("success", "Registro incluído");
    //       }
    //     }
    //   } catch (error) {
    //     this.offLoader();
    //     if (error.response) {
    //       if (error.response.status === 404) {
    //         this.makeToast("danger", "Destino URL não encontrado!");
    //       } else {
    //         this.makeToast("danger", error);
    //       }
    //     }
    //   }
    // },
    // async doPut_prestacao(prestacao) {
    //   this.onLoader();

    //   try {
    //     let response = await http.put(
    //       "/prestacaoConta/" +
    //         prestacao.id +
    //         "?empresa_id=" +
    //         this.currentEmpresa.id,
    //       prestacao
    //     );
    //     if (response) {
    //       this.offLoader();
    //       if (response.status === 200) {
    //         this.makeToast("success", "Registro alterado");
    //         this.getData();
    //         this.editando = false;
    //       }
    //     }
    //   } catch (error) {
    //     this.offLoader();
    //     if (error.response) {
    //       if (error.response.status === 404) {
    //         this.makeToast("danger", "Destino URL não encontrado!");
    //       } else {
    //         this.makeToast("danger", error);
    //       }
    //     }
    //   }
    // },
    post_abastecimento(objeto) {
      //console.log("entrei no list de abastecimento vet Abastecimento" )
      //console.log(objeto.vet_abastecimento)
      this.$emit("doPostabastecimento", objeto);
    },

    setPut(objeto) {
      //console.log(objeto)
      this.$emit("doPut_prestacao", objeto);
    },
    setPost(objeto) {
      //console.log("entrei aqui no list function POst")
      //  console.log(objeto)
      this.$emit("doPost_prestacao", objeto);
    },
    validar() {
      // row.item.prestacao_conta.situacao != null :
    },
  },
};
</script>

<template>
  <div class="card-body" v-if="hide">
    <div class="row mt-4">
      <!-- Start Limit -->
      <div class="col-sm-12 col-md-6">
        <div id="tabelas-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center">
            Exibir&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select
            >&nbsp;registros
          </label>
        </div>
      </div>
      <!-- End Limit -->
      <!-- Start Search -->
      <div class="col-sm-12 col-md-6">
        <div id="produto-table_filter" class="dataTables_filter text-md-right">
          <label class="d-inline-flex align-items-center">
            Pesquisar:
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Pesquisar..."
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
        </div>
      </div>
      <!-- End search -->
    </div>
    <!-- Start Table -->
    <div class="row mt-4">
      <div v-if="hide" class="table-responsive mb-0">
        <b-table
          :items="listTabelas"
          :fields="fields"
          responsive="sm"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          :hover="true"
          :foot-clone="clonefoot"
        >
          <!-- prestacao_conta -->
          <template #cell(acoes)="row">
            <!-- `data.value` is the value after formatted by the Formatter -->
            <b-dropdown v-bind:id="'dropdown-' + row.item.id" class="m-md-2">
              <template #button-content>
                <i data-v-6289eca4="" class="bx ri-menu-line"></i>
              </template>
              <b-dropdown-item
                v-b-modal.modal-tabelas
                @click="setTabelas(row.item)"
                ><i class="bx ri-eraser-fill"></i> Visualizar</b-dropdown-item
              >
              <b-dropdown-item
                v-b-modal.modal-carregamento
                @click="setCarregamento(row.item)"
                ><i class="bx ri-eraser-fill"></i> Carregamento</b-dropdown-item
              >
              <b-dropdown-item @click="setEdit(row.item)"
                ><i class="bx ri-edit-2-line"></i> Alterar</b-dropdown-item
              >
              <b-dropdown-item
                v-b-modal.modal-viagem
                @click="setViagem(row.item)"
                ><i class="bx ri-edit-2-line"></i> Acompanhamento de
                Viagem</b-dropdown-item
              >
              <b-dropdown-item
                v-b-modal.modal-presta-conta
                @click="setPrestacao(row.item)"
                ><i class="bx ri-edit-2-line"></i> Prestação de
                Contas</b-dropdown-item
              >
              <b-dropdown-item @click="setDelete(row.item)"
                ><i class="bx ri-eraser-fill"></i> Excluir</b-dropdown-item
              >
            </b-dropdown>
          </template>

          <template #cell(situacao)="row">
            <!-- <div>
              <b-badge
                data-name="credito"
                class="field-status"
                v-bind:variant="
                  row.item.prestacao_conta.situacao != 'f'
                    ? 'success'
                    : 'success'
                "
                >{{
                  row.item.prestacao_conta.situacao == 1 
                    ? "Embarcado"
                    : "Não Embarcado"
                }}</b-badge
              >
            </div> -->
            <b-badge
              data-name="credito"
              class="field-status"
              :variant="
              row.item.prestacao_conta != undefined ?  'success' : 'danger'  
              
              "
            >
              {{
                row.item.prestacao_conta != undefined
                  ? row.item.prestacao_conta.situacao == 1
                    ? "embarcado"
                    : row.item.prestacao_conta.situacao == 2
                    ? "entrege"
                    : "nao embarcado"
                  : row.item.prestacao_conta == undefined
                  ? "Não Embarcado"
                  : "embarcado"
              }}
            </b-badge>
          </template>
        </b-table>
      </div>
    </div>
    <!-- End Table -->
    <!-- Start Pagination -->
    <div v-if="hide" class="row mt-4">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
    <!-- End Pagination -->
    <!-- Start Modal Produto -->
    <b-modal
      v-if="hide"
      hide-footer
      id="modal-tabelas"
      ref="modal-tabelas"
      :title="'Detalhes da tabelas'"
      title-class="font-18"
    >
      <Detail :tabelas="currentTabelas" :motoristas="motoristas" />
    </b-modal>
    <b-modal
      headerBgVariant="light"
      v-if="hide"
      size="xl"
      hide-footer
      id="modal-presta-conta"
      ref="modal-presta-conta"
      :title="'Prestação de Contas'"
      title-class="font-18"
    >
      <conta
        @post_abastecimento="post_abastecimento"
        @setPut="setPut"
        @setPost="setPost"
        :conta_atual="currentTabelas"
      />
    </b-modal>
    <b-modal
      headerBgVariant="light"
      v-if="hide"
      size="xl"
      hide-footer
      id="modal-carregamento"
      ref="modal-carregamento"
      :title="'Carregemtnos'"
      title-class="font-18"
    >
      <carregamento :conta_atual="currentTabelas" />
    </b-modal>
    <b-modal
      headerBgVariant="light"
      v-if="hide"
      size="xl"
      hide-footer
      id="modal-viagem"
      ref="modal-viagem"
      :title="'Acompanhar Viagem'"
      title-class="font-18"
    >
      <viagem
        @post_viagem="post_viagem"
        :motoristas="motoristas"
        :veiculos="veiculos"
        :objeto_atual="currentTabelas"
      />
    </b-modal>
    <!-- End Modal Produto -->
  </div>
</template>