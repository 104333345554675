<template>
  <b-modal
    v-model="car_modal"
    size="xl"
    title="Cadastro de Veiculo"
    hide-footer
    @hidden="back()"
  >
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-md-3">
            <Label>Placa:</Label>
            <input v-model="veiculo.placa" type="text" class="form-control" />
          </div>
          <div class="col-md-3">
            <Label>Cidade:</Label>
            <multiselect
              @select="select_cidade($event)"
              v-model="sec_cidade"
              :options="cidades"
              label="nome"
            >
            </multiselect>
          </div>
          <div class="col-md-3">
            <Label>Origem:</Label>

            <multiselect
              @select="select_origem($event)"
              v-model="sec_origem"
              :options="origens"
              label="nome"
            >
            </multiselect>
          </div>
          <div class="col-md-3">
            <Label>{{ "*Km atual do veiculo" }}:</Label>
            <input
              @input="formatInput_valor()"
              v-model="veiculo.km_atual"
              type="text"
              class="form-control text-left"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-3">
            <Label>Tara:</Label>
            <input v-model="veiculo.tara" type="text" class="form-control" />
          </div>
          <div class="col-md-3">
            <Label>Capacidade de Carga:</Label>
            <input
              @input="formatInput_valor_cap()"
              v-model="veiculo.capacidade_max"
              type="text"
              class="form-control"
            />
          </div>
          <div class="col-md-3">
            <Label>Tipo de Carroceria:</Label>
            <multiselect
              @select="select_tp_carroceria($event)"
              v-model="sec_tp_carroceria"
              :options="carrocerias"
              label="nome"
            >
            </multiselect>
          </div>
          <div class="col-md-3">
            <Label>{{ "Tipo Rodado" }}:</Label>
            <multiselect
              @select="select_tp_rodado($event)"
              v-model="sec_tp_rodado"
              :options="tp_rodadados"
              label="nome"
            >
            </multiselect>
          </div>
        </div>
        <b-card-header header-tag="header" class="p-1 mt-4" role="tab">
          <b-button block v-b-toggle.pedido variant="light"
            >Proprietario/Transportador</b-button
          >
        </b-card-header>
        <b-collapse id="pedido" accordion="pedido-accordion" role="tabpanel">
          <div
            class="row d-flex justify-content-center align-items-center mt-4"
          >
            <div class="col-md-12 text-center">
              <!-- <Label>Proprietario/Transportador:</Label> -->
            </div>
          </div>
          <div
            class="row mt-4 d-flex justify-content-center align-items-center mb-4"
          >
            <div class="col-md-6 text-center">
              <Label class="font"
                ><b-badge
                  data-name="juridica"
                  class="field-status"
                  variant="info"
                  >{{ "Pessoa Fisica" }}</b-badge
                ></Label
              >
              <input
                @change="set_nome_cpf_cnpj()"
                v-model="veiculo.tp_pessoa"
                type="radio"
                value="1"
                class="form-control"
              />
            </div>
            <div class="col-md-6 text-center">
              <Label class="font"
                ><b-badge
                  data-name="juridica"
                  class="field-status"
                  variant="info"
                  >{{ "Pessoa Juridica" }}</b-badge
                ></Label
              >
              <input
                @change="set_nome_cpf_cnpj()"
                v-model="veiculo.tp_pessoa"
                type="radio"
                value="2"
                class="form-control"
              />
            </div>
          </div>

          <div class="row mt-4 mb-4">
            <div class="col-md-4">
              <Label>{{ nome_tp_pessoa }}</Label>
              <input
                v-model="veiculo.nomeRazao"
                type="text"
                class="form-control text-left"
              />
            </div>
            <div class="col-md-4">
              <Label>{{ cpfcnpj }}</Label>
              <input
                v-model="veiculo.cpfcnpj"
                type="text"
                class="form-control text-left"
              />
            </div>
            <div class="col-md-4">
              <Label>RNTRC/ANTT</Label>
              <input
                v-model="veiculo.rntrc"
                type="text"
                class="form-control text-left"
              />
            </div>
          </div>
          <div class="row">
            <Label>Observações: </Label>
            <div class="col-md-12">
              <textarea
                v-model="veiculo.obs"
                name=""
                id=""
                cols="30"
                rows="10"
                class="form-control text-left"
              ></textarea>
            </div>
          </div>
        </b-collapse>
        <div class="row d-flex justify-content-center align-items-center mt-4">
          <div class="col-md-12 text-center">
            <button class="btn btn-secondary" @click.prevent="doPost()">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Multiselect from "vue-multiselect";
import { http } from "../../../../helpers/easyindustriaapi/config";
export default {
  components: {
    Multiselect,
  },
  props: {
    open_modal_car: { type: Boolean },
  },
  data() {
    return {
      name: null,
      car_modal: false,
      cidades: [
        {
          id: 1,
          nome: "Petrolina",
        },
        {
          id: 2,
          nome: "juazeiro",
        },
      ],
      origens: [
        {
          id: 1,
          nome: "Terceiros",
        },
        {
          id: 2,
          nome: "Particular",
        },
      ],
      carrocerias: [
        {
          id: 1,
          nome: "não se aplica",
        },
        {
          id: 2,
          nome: "Aberta",
        },
        {
          id: 3,
          nome: "Bau/fechada",
        },
        {
          id: 4,
          nome: "granelera",
        },
        {
          id: 5,
          nome: "porta Container",
        },
        {
          id: 6,
          nome: "Sider",
        },
      ],
      tp_rodadados: [
        {
          id: 1,
          nome: "Truck",
        },
        {
          id: 2,
          nome: "Toco",
        },
        {
          id: 3,
          nome: "Cavalo Mecanico",
        },
        {
          id: 4,
          nome: "Van",
        },
        {
          id: 5,
          nome: "Utilitarios",
        },
        {
          id: 6,
          nome: "Outros",
        },
      ],
      veiculo: {
        placa: null,
        cidadade_id: null,
        origem_id: null,
        km_atual: null,
        tara: null,
        capacidade_max: null,
        carroceria_id: null,
        tp_rodado: null,
        tp_pessoa: null,
        nomeRazao: null,
        cpfcnpj: null,
        rntrc: null,
        obs: null,
      },
      sec_cidade: null,
      sec_origem: null,
      sec_tp_carroceria: null,
      sec_tp_rodado: null,
      tp_pessoa: null,
      nome_tp_pessoa: "Razão Social",
      cpfcnpj: "CNPJ",
    };
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("user")); // para saber qual o usuario atual;
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
    this.car_modal = this.open_modal_car;
  },
  mounted() {},
  methods: {
    formatInput_valor() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.veiculo.km_atual.replace(/\D/g, "");
      // Formate o valor_pag numérico para a moeda desejada (por exemplo, BRL)
      this.veiculo.km_atual = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    formatInput_valor_cap() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.veiculo.capacidade_max.replace(/\D/g, "");
      // Formate o valor_pag numérico para a moeda desejada (por exemplo, BRL)
      this.veiculo.capacidade_max = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    coverter_float() {
      //typeof(this.pre_ped.valor)
      if (typeof this.veiculo.km_atual == "string") {
        this.veiculo2 = this.veiculo;
        let stringValor = this.veiculo2.km_atual;
        let valorFlutuante = parseFloat(
          stringValor.replace("R$", " ").replace(/\./g, "").replace(",", ".")
        );
        this.veiculo.km_atual = valorFlutuante;
      }
      if (typeof this.veiculo.capacidade_max == "string") {
        this.veiculo3 = this.veiculo;
        let stringValor = this.veiculo3.capacidade_max;
        let valorFlutuante = parseFloat(
          stringValor.replace("R$", " ").replace(/\./g, "").replace(",", ".")
        );
        this.veiculo.capacidade_max = valorFlutuante;
      }
    },
    select_cidade(e) {
      this.veiculo.cidadade_id = e.id;
    },
    select_origem(e) {
      this.veiculo.origem_id = e.id;
    },
    select_tp_carroceria(e) {
      this.veiculo.carroceria_id = e.id;
    },
    select_tp_rodado(e) {
      this.veiculo.tp_rodado = e.id;
    },
    set_nome_cpf_cnpj() {
      console.log(this.veiculo.tp_pessoa);
      if (this.veiculo.tp_pessoa == 1) {
        this.nome_tp_pessoa = "Nome";
        this.cpfcnpj = "CPF";
      } else if (this.veiculo.tp_pessoa == 2) {
        this.nome_tp_pessoa = "Razão Social";
        this.cpfcnpj = "CNPJ";
      } else {
        this.this.nome_tp_pessoa = "Razão Social";
        this.cpfcnpj = "CNPJ";
      }
    },
    back() {
      this.$emit("back");
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    async doPost(veiculo) {
      // id
      // DescricaoUn
      // un_codigo
      // empresa_id
      this.coverter_float();
      veiculo = this.veiculo;
      veiculo.empresa_id = this.currentEmpresa.id;
      // embarque.user_id = this.currentUser.id;
      //this.onLoader();
      try {
        let response = await http.post(
          "/veiculo?empresa_id=" + this.currentEmpresa.id,
          veiculo
        );
        if (response) {
          //this.offLoader();
          if (response.status === 200) {
            //this.getData();
            //this.inserindo = false;
            this.makeToast("success", "Registro incluído");
          }
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.font {
  font-size: 26px;
}
</style>