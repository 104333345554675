<template>
  <b-modal
    v-model="show_modal"
    size="xl"
    title="Registara despesa"
    hide-footer
    @hidden="back()"
    headerBgVariant="light"
  >
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <Label>Definir Padrão:</Label>
            <multiselect :options="padroes" label="nome"> </multiselect>
          </div>
          <div class="col-md-4">
            <Label>{{ "*Descrição" }}:</Label>
            <input type="text" class="form-control text-left" />
          </div>
          <div class="col-md-4">
            <Label>{{ "*Valor" }}:</Label>
            <input type="text" class="form-control text-left" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <Label>{{ "*Especie de Pagamento" }}:</Label>
            <multiselect :options="Pagamentos" label="nome"> </multiselect>
          </div>
          <div class="col-md-4">
            <Label>{{ "Vencimento" }}:</Label>
            <input type="date" class="form-control text-left" />
          </div>
          <div class="col-md-4">
            <Label>{{ "Pagamento" }}:</Label>
            <input type="date" class="form-control text-left" />
          </div>
        </div>
        <div class="mt-4 row d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center mb-4">
            <Label class="">{{ "*Esta Conta já Foi Paga ?" }}</Label>
          </div>
        </div>
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center mb-4">
            <!-- <Label class="mb-4">{{ "*Esta Conta já Foi Paga ?" }}</Label> -->
            <!-- <b-form-checkbox> sim </b-form-checkbox>
            <b-form-checkbox> não </b-form-checkbox> -->
            <input type="radio" >
            <Label class="ml-2">sim</Label>
            <input type="radio" class="ml-2">
            <Label class="ml-2">não</Label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <Label>{{ "conta" }}</Label>
            <multiselect :options="contas"> </multiselect>
          </div>
          <div class="col-md-4">
            <Label>{{ "Plano de Conta" }}</Label>
            <multiselect :options="planos"> </multiselect>
          </div>
          <div class="col-md-4">
            <Label>{{ "N_doc" }}</Label>
            <input type="text" class="form-control text-left" />
          </div>
        </div>
        <div class="mt-4 row d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center">
            <Label> Doc Fiscal </Label>
            <!-- <b-form-checkbox> sim </b-form-checkbox>
            <b-form-checkbox> não </b-form-checkbox> -->
          </div>
        </div>
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center">
            <input type="radio">
            <label for="" class="ml-2 mr-2">sim</label>
            <input type="radio">
            <label for="" class="ml-2">não</label>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-4">
            <Label>Empresa</Label>
            <multiselect :options="empresas"></multiselect>
          </div>
          <div class="col-md-4">
            <b-form-checkbox class="mb-2"> Vincular a um aviso de Embarque </b-form-checkbox>
            <multiselect :options="empresas"></multiselect>
          </div>
          <div class="col-md-4">
            <b-form-checkbox class="mb-2"> referencia ao faturamento </b-form-checkbox>
            <input type="text" class="form-control text-left" />
          </div>
        </div>
        <div class="mt-4 mb-4 row row d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center">
            <!-- criar logicas para aprece os outros inputs  -->
            <b-form-checkbox>Vincular a um fornecedor</b-form-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <Label>Observações:</Label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="1"
              class="form-control"
            ></textarea>
          </div>
          <div class="col-md-6">
            <Label>Ref.Localização(Documento):</Label>
            <input type="text" class="form-control text-left" />
          </div>
        </div>
        <div class="mt-4 mb-4 row d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center">
            <b-form-checkbox>Repetir Registro</b-form-checkbox>
          </div>
        </div>
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center">
            <button class="btn btn-secondary">Salvar</button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  props: {
    veri_desp: { type: Boolean },
  },
  components:{
    Multiselect
  },
  data() {
    return {
      empresas: [
        {
          id: 1,
          nome: "empresa 1",
        },
      ],
      padroes: [
        {
          id: 1,
          nome: "Padrão1",
        },
        {
          id: 2,
          nome: "Padrão1",
        },
      ],
      Pagamentos: [
        {
          id: 1,
          nome: "dinheiro",
        },
      ],
      contas: [
        {
          id: 1,
          nome: "conta1",
        },
      ],
      planos: [
        {
          id: 1,
          nome: "plano1",
        },
      ],
      show_modal: false,
    };
  },
  created() {
    this.show_modal = this.veri_desp;
  },
  mounted() {},
  methods: {
    back() {
      this.$emit("back");
    },
  },
};
</script>

<style>
</style>