<template>
  <b-modal
    v-model="show_modal"
    size="xl"
    title="Registar Recebimento do Cliente"
    hide-footer
    @hidden="back()"
  >
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-md-12">
            <Label>Cliente:</Label>
            <multiselect
              @select="cliente_selecionado($event)"
              @remove="cliente_selecionado()"
              v-model="select_cliente"
              :options="clientes"
              label="pes_apelido"
            ></multiselect>
          </div>
        </div>
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.pedido variant="light">Registrar</b-button>
        </b-card-header>
        <b-collapse id="pedido" accordion="pedido-accordion" role="tabpanel">
          <div class="row">
            <div class="col-md-4">
              <Label>Total Selecionado: </Label>
              <input v-model="recebimento.tot_selecinado" type="text" class="form-control text-left" />
            </div>
            <div class="col-md-4">
              <Label>Valor Recebido: </Label>
              <input @input="formatInput_valor()" v-model="recebimento.vlr_recebido" type="text" class="form-control text-left" />
            </div>
            <div class="col-md-4">
              <Label>Data do Recebimento: </Label>
              <input v-model="recebimento.data_recebimento" type="date" class="form-control text-left" />
            </div>
            <!-- <div class="col-md-4">
            <Label class="mb-4">&nbsp; </Label>
            <b-form-checkbox>conc.venda Direta</b-form-checkbox>
          </div> -->
          </div>
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-md-12 text-center mt-4">
              <b-form-checkbox >conc.venda Direta</b-form-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <Label>Conta:</Label>
              <multiselect label="nome" v-model="select_conta" @select="seleciona_conta($event)" :options="contas"> </multiselect>
            </div>
            <div class="col-md-4">
              <Label>Especie:</Label>
              <multiselect label="nome" v-model="select_especie" @select="seleciona_especie($event)" :options="especies"> </multiselect>
            </div>
            <div class="col-md-4">
              <Label>N_doc:</Label>
              <input
                v-model="recebimento.n_doc"
                type="text"
                class="form-control text-left"
                placeholder="digite"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <Label>*Descrição:</Label>
              <input
              v-model="recebimento.desc"
                type="text"
                class="form-control text-left"
                placeholder="digite"
              />
            </div>
          </div>
          <div
            class="row mt-4 d-flex justify-content-center align-items-center"
          >
            <div class="col-md-12 text-center">
              <button class="btn btn-secondary" @click="enviar_recebimentos()">Receber</button>
            </div>
          </div>
        </b-collapse>
        <div v-if="cliente_veri" class="row mt-4">
          <div class="col-md-12">
            <b-table
              striped
              hover
              :items="contas_receber"
              responsive
              id="doc-itens"
              :fields="fields"
            >
              <template #cell(excluir)="row">
                <button
                  @click="dlt_abastecimento(row.item)"
                  class="btn btn-danger"
                >
                  Excluir
                </button>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Multiselect from "vue-multiselect";
import { http } from "../../../../helpers/easyindustriaapi/config";
export default {
  props: {
    veri_reg_receb: { type: Boolean },
    prestacao_objeto: {type: Object}
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      nome: "cliente",
      clientes: [],
      especies: [
        {
          id: 1,
          nome: "especie1",
        },
        {
          id: 2,
          nome: "especie2",
        },
      ],
      select_cliente: null,
      cliente_veri: false,
      show_modal: false,
      contas_receber: [],
      fields: [
        {
          label: "Número",
          key: "num",
          tdClass: "text-center",
          thClass: "text-center",
          variant: "info",
        },
        {
          label: "Fatura",
          key: "km_inicial",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Descrição ",
          key: "qtd_litros",
          tdClass: "text-center",
          thClass: "text-center",
          variant: "info",
        },
        {
          label: "Data",
          key: "preco_litro",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Vencimento",
          key: "vencimento",
          tdClass: "text-center",
          thClass: "text-center",
          variant: "info",
        },
        {
          label: "Valor",
          key: "valor",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Juros",
          key: "juros",
          tdClass: "text-center",
          thClass: "text-center",
          variant: "info",
        },
        {
          label: "Desc",
          key: "Desc",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Total",
          key: "tot",
          tdClass: "text-center",
          thClass: "text-center",
          variant: "info",
        },
        // { label: "Número", key: "numero", tdClass: 'text-center', thClass: 'text-center' },
        // { label: "Série", key: "serie", tdClass: 'text-center', thClass: 'text-center', formatter: "formatterCurrBR" },
        // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
        {
          label: "Ações",
          key: "excluir",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      contas: [
        {
          id: 1,
          nome: "thiago",
        },
      ],
      recebimento:{
        aviso_embarque_id:null,
        cliente_id:null,
        tot_selecinado:23,
        vlr_recebido:null,
        data_recebimento:null,
        conta_id:null,
        especie_id:null,
        n_doc:null,
        desc:null
      },
      select_conta:null,
      select_especie:null,

    };
  },
  created() {
    this.show_modal = this.veri_reg_receb;
    this.recebimento.id = this.prestacao_objeto.aviso_embarque_id
  },
  mounted() {
    this.get_pessoa_cliente();
  },
  methods: {
    formatInput_valor() {
      // Remova todos os caracteres não numéricos do valor de entrada
      let numericValue = this.recebimento.vlr_recebido.replace(/\D/g, "");
      // Formate o valor_pag numérico para a moeda desejada (por exemplo, BRL)
      this.recebimento.vlr_recebido = parseFloat(numericValue / 100)
        .toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        })
        .replace("R$", " ");
    },
    seleciona_conta(event){
     console.log(event)
     this.recebimento.conta_id = event.id
    },
    seleciona_especie(event){
      console.log(event)
      this.recebimento.especie_id = event.id
    },
    enviar_recebimentos(){
         this.$emit('item_recebimento' , this.recebimento)
    },
    async get_pessoa_cliente() {
      // depois passar o empresa id , pq senão vai trazer de todas as empresas;
      // + "&empresa_id=" + this.currentEmpresa.id
      try {
        let response = await http.get("/cliente/?cliente_id=" + -1);
        if (response.status === 200) {
          this.clientes = response.data;
          //   console.log("motoristaas")
            console.log(this.clientes)
          setTimeout(() => {
            //this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.clientes = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          //this.offLoader();
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    cliente_selecionado(objeto_cliente) {
      console.log(objeto_cliente);
      if (objeto_cliente != undefined) {
        this.cliente_veri = true;
      } else {
        this.recebimento.cliente_id = objeto_cliente.id
        this.cliente_veri = false;
      }
    },
    back() {
      this.cliente_veri = false;
      this.$emit("back");
    },
  },
};
</script>

<style>
</style>