<script>
import Multiselect from "vue-multiselect";
import required from "vuelidate/lib/validators";
import car from "./insert_veiculo.vue";
import rota from "./insert_rota.vue";
import { http } from "../../../../helpers/easyindustriaapi/config";

export default {
  components: {
    Multiselect,
    car,
    rota,
  },
  props: {
    showModal: {
      type: Boolean,
    },
  },
  data() {
    return {
      loader: { get: false },
      titleBody: "Incluir Informações de Embarque",
      tabelas: {
        ttp_nome: null,
      },
      motorista: [
        {
          id: 1,
          nome: "motorista1",
        },
      ],
      veiculos: [
        {
          id: 1,
          nome: "veiculos1",
        },
      ],
      rotas: [
        {
          id: 1,
          nome: "rota1",
        },
      ],
      insert_embarque: {
        motorista_id: null,
        veiculo_id: null,
        rota_id: null,
        identificacao: null,
        notas: null,
        data_carregamento: null,
        data_previsao: null,
        obs: null,
      },
      select_motorista: null,
      select_rota: null,
      select_veiculos: null,
      open_modal_car: false,
      open_modal_rota: false,
      pre_pedidos: [],
      select_all: null,
      motoristas:[]
    };
  },
  validations() {
    return {
      tabelas: {
        ttp_nome: { required },
      },
    };
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("user")); // para saber qual o usuario atual;
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
  },
  mounted() {
    this.setNewTitle();
    this.get_rota();
    this.get_veiculo();
    this.get_prePedidos();
    this.get_pessoa_motorist();
  },
  methods: {
    async get_pessoa_motorist(){
      try {
        // let response = await http.get(
        //   "/motorista?motorista_id=" + -6
        // );
        // "/fornecedor?empresa_id=" + this.currentEmpresa.id + "&fornecedor_id=" + -2
        let response = await http.get(
          "/motorista?empresa_id=" + this.currentEmpresa.id  + "&motorista_id=" + -6
        );
        if (response.status === 200) {
          this.motoristas = response.data;
          console.log(this.motoristas)
        } else {
          this.motoristas = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          //this.offLoader();
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async get_veiculo() {
      try {
        let response = await http.get(
          "/veiculo?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.veiculos = response.data;
          // console.log(this.veiculos)
          setTimeout(() => {
            //this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.veiculos = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
         // this.offLoader();
        }
      } catch (error) {
        ///this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async get_prePedidos() {
      try {
        let response = await http.get(
          "/pre-pedido?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.pre_pedidos = response.data;
          // console.log(this.veiculos)
          setTimeout(() => {
            //this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.pre_pedidos = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          //this.offLoader();
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    async get_rota() {
      try {
        let response = await http.get(
          "/rota?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.rotas = response.data;
          //console.log(this.rotas)
          setTimeout(() => {
          //  this.offLoader();
          }, 2000); // tempo para carregar a tela;
        } else {
          this.rotas = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
         // this.offLoader();
        }
      } catch (error) {
       // this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    modal_car() {
      this.open_modal_car = true;
    },
    modal_rota() {
      this.open_modal_rota = true;
    },
    back() {
      this.open_modal_rota = false;
      this.open_modal_car = false;
    },
    setNewTitle() {
      this.$emit("newTitle", this.titleBody);
    },
    setPost() {
      this.$emit("doPost", this.insert_embarque);
      this.voltar();
    },
    voltar() {
      this.$emit("back");
    },
    seleciona_moto(event) {
      //console.log(event)
      this.insert_embarque.motorista_id = event.id;
    },
    seleciona_rota(event) {
      this.insert_embarque.rota_id = event.id;
      //console.log(event)
    },
    seleciona_veiculo(event) {
      this.insert_embarque.veiculo_id = event.id;
      ///console.log(event)
    },
    customLabel_car({ id, nomeRazao }) {
      return `${id} – ${nomeRazao}`;
    },
    customLabel_rota({ id, nome }) {
      return `${id} – ${nome}`;
    },
    seleciona_todos() {},
    seleciona_pre_pedido(item) {
      console.log(item);
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="row mb-4">
        <div class="col-md-4">
          <!-- insert_embarque: {
        motorista: null,
        veiculo: null,
        rota: null,
        identificacao: null,
        notas: null,
        data_carregamento: null,
        data_previsao: null,
        obs: null,
      }, -->
          <Label>{{ "*Motorista" }}</Label>
          <Multiselect
            @select="seleciona_moto($event)"
            v-model="select_motorista"
            :options="motoristas"
            label="pes_apelido"
          >
          </Multiselect>
        </div>
        <div class="col-md-4">
          <Label>{{ "*Veiculos" }}</Label>
          <multiselect
            v-model="select_veiculos"
            placeholder="Selecione"
            label="nomeRazao"
            track-by="nomeRazao"
            :options="veiculos"
            :option-height="104"
            :show-labels="false"
            deselect-label="ENTER para remover"
            select-label="Pressione ENTER"
            @select="seleciona_veiculo($event)"
            :custom-label="customLabel_car"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc mr-4">
                <span class="option__title"
                  >{{ props.option.id }} - {{ props.option.nomeRazao }}</span
                >
              </span>
              <!-- <button class="btn btn-secondary ml-4">ola</button> -->
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title mr-4"
                  >{{ props.option.id }} - {{ props.option.nomeRazao }}</span
                >
                <button class="btn btn-secondary ml-4" @click="modal_car()">
                  Cadastrar Veiculo
                </button>
              </div>
            </template>
            <span slot="noResult">Opa! Nenhum registro encontrado</span>
            <button slot="noResult" class="btn btn-primary ml-2">
              Cadastrar cliente
            </button>
            <template>
              <span slot="noOptions" class="mr-2"
                >Lista Vazia Cadastre o Cliente</span
              >
              <button
                @click="abrir_modal_cliente()"
                slot="noOptions"
                class="btn btn-primary ml-2"
              >
                Cadastrar cliente
              </button>
            </template>
          </multiselect>
          <div v-if="open_modal_car">
            <car @back="back" :open_modal_car="open_modal_car"></car>
          </div>
        </div>
        <div class="col-md-4">
          <Label>{{ "*Rota/Destino" }}</Label>
          <multiselect
            v-model="select_rota"
            placeholder="Selecione"
            label="nome"
            track-by="nome"
            :options="rotas"
            :option-height="104"
            :show-labels="false"
            deselect-label="ENTER para remover"
            select-label="Pressione ENTER"
            @select="seleciona_rota($event)"
            :custom-label="customLabel_rota"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc mr-4">
                <span class="option__title"
                  >{{ props.option.id }} - {{ props.option.nome }}</span
                >
              </span>
              <!-- <button class="btn btn-secondary ml-4">ola</button> -->
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title mr-4"
                  >{{ props.option.id }} - {{ props.option.nome }}</span
                >
                <button class="btn btn-secondary ml-4" @click="modal_rota()">
                  Cadastrar Rota
                </button>
              </div>
            </template>
            <span slot="noResult">Opa! Nenhum registro encontrado</span>
            <button
              slot="noResult"
              class="btn btn-primary ml-2"
              @click="modal_rota()"
            >
              Cadastrar Rota
            </button>
            <template>
              <span slot="noOptions" class="mr-2"
                >Lista Vazia Cadastre o Cliente</span
              >
              <button
                @click="modal_rota()"
                slot="noOptions"
                class="btn btn-primary ml-2"
              >
                Cadastrar Rota
              </button>
            </template>
          </multiselect>
          <div v-if="open_modal_rota">
            <rota @back="back" :open_modal_rota="open_modal_rota"></rota>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-3">
          <Label>{{ "*Identificação" }}</Label>
          <input
            v-model="insert_embarque.identificacao"
            type="text"
            class="form-control text-left"
          />
        </div>
        <div class="col-md-3">
          <Label>{{ "Notas" }}</Label>
          <input
            v-model="insert_embarque.notas"
            type="text"
            class="form-control text-left"
          />
        </div>
        <div class="col-md-3">
          <Label>{{ "previsão de Carregamento" }}</Label>
          <input
            v-model="insert_embarque.data_carregamento"
            type="date"
            class="form-control text-left"
          />
        </div>
        <div class="col-md-3">
          <Label>{{ "previsão de Entrega" }}</Label>
          <input
            v-model="insert_embarque.data_previsao"
            type="date"
            class="form-control text-left"
          />
        </div>
      </div>

      <div class="row mt-4 d-flex justify-content-center align-items-center">
        <div class="col-md-12 text-center">
          <!-- <b-form-checkbox> Pedidos </b-form-checkbox> -->
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pedido variant="light">pedido</b-button>
          </b-card-header>
          <b-collapse id="pedido" accordion="pedido-accordion" role="tabpanel">
            <div class="row">
              <div class="col-md-12">
                <b-form-checkbox
                  v-model="select_all"
                  @change="seleciona_todos()"
                  value="1"
                >
                  Selecionar Todos
                </b-form-checkbox>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-4"
                v-for="(item, index) in pre_pedidos"
                :key="(index = item.id)"
              >
                <div class="card">
                  <div class="card-body">
                    <b-form-checkbox
                      v-model="item.select_item"
                      @change="seleciona_pre_pedido(item)"
                      :value="index"
                    >
                      {{ "Pedido n° :" }}-{{ index }} - {{ "Valor :" }}
                      {{ item.valor }}
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.iten variant="light">Observação</b-button>
      </b-card-header>
      <b-collapse id="iten" accordion="pedido-accordion" role="tabpanel">
        <div class="row mt-4 d-flex justify-content-center align-items-center">
          <div class="col-md-12 text-center">
            <textarea
              v-model="insert_embarque.obs"
              name=""
              id=""
              cols="30"
              rows="10"
              class="form-control text-left"
            ></textarea>
          </div>
        </div>
      </b-collapse>
      <div class="row mt-4 d-flex justify-content-center align-items-center">
        <div class="col-md-12 text-center">
          <button class="btn btn-secondary" @click="setPost()">Salvar</button>
        </div>
      </div>
    </div>
  </div>
</template>